
import { Options } from "vue-class-component";
import ComponentMixins from "../ComponentMixins";

@Options({
    name: "flowable-common-form-components-multiple-select",
    props: {
        placeholder: {
            type: String,
            default: "请选择选项",
        },
        expanding: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: (): any => {
                return [];
            },
        },
        readerMode: {
            type: Boolean,
            default: false,
        },
    },
})
export default class MultipleSelect extends ComponentMixins {
    private checks: any = [];

    private readerValue() {
        return this._value.join(", ");
    }
}
